
    import {
        defineComponent,
        ref,
        inject,
    } from "vue";
    import Toast from "@/components/UI/Toast";
    import api from "@/api/axios";
    import { useRouter, useRoute } from "vue-router";
    import Modal from "@/components/UI/Modal";
    export default defineComponent({
        name: "InviteRegister",
        emits: ["loginsuccess"],
        setup(props, ctx) {

            const router = useRouter();
            const route = useRoute();
            const invitationCode = ref(route.query.invitationCode);
            const addType = ref(route.query.addType);
            if(!addType.value){
                addType.value ='2';
            }
            const userName = ref("");
            console.log('invitationCode',invitationCode.value)
            //code 登录
            const telphone = ref<string>("");
            const code = ref<string>("");
            const passCode = ref<string>("");
            const passwordNew = ref<string>("");
            const sendCodeFlag = ref<boolean>(true);
            const codeText = ref<string>("获取验证码");
            let timer: any = ref(null);
            const count = ref(60);

            if(invitationCode.value){
                api
                    .get("/M/User/getUserName?userId="+invitationCode.value)
                    .then((res) => {
                        if (res.data.success) {
                            userName.value = res.data.obj;
                        } else {
                            Toast({
                                type: "error",
                                title: res.data.msg,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log('err')
                    });
            }

            //手机号校验
            function checkTel(tel: string) {
                if (tel === "") {
                    Toast({
                        title: "请输入手机号码",
                        type: "warning",
                    });
                    return false;
                }
                const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
                if (tel.length != 11 || !retel.test(tel)) {
                    Toast({
                        title: "请输入正确的手机号码",
                        type: "warning",
                    });
                    // $('#tel').focus();
                    return false;
                }
                return true;
            }

            //获取验证码
            function getSMSCode() {
                if (!checkTel(telphone.value)) {
                    return false;
                }
                if (!passCode.value) {
                    Toast({
                        title: "请输入图形验证码",
                        type: "warning",
                    });
                    return false;
                }
                if( !sendCodeFlag.value ){
                    return false
                }
                sendCodeFlag.value = false;
                //获取验证码
                const formData = new FormData();
                formData.append("tel", telphone.value);
                formData.append("option", "registerOpt");
                formData.append("passcode", passCode.value);
                api
                    .post("/M/Server/GetNewSMSCode", formData)
                    .then((res) => {
                        if (res.data.success) {
                            Toast({
                                title: "验证码发送成功，请注意查收",
                                type: "success",
                            });
                            count.value = 60;
                            codeText.value = count.value + "s";
                            timer = setInterval(function () {
                                count.value--;
                                if (count.value == 0) {
                                    clearInterval(timer);
                                    sendCodeFlag.value = true;
                                    codeText.value = "获取验证码";
                                } else {
                                    codeText.value = count.value + "s";
                                }

                            }, 1000);
                        } else {
                            sendCodeFlag.value = true;
                            Toast({
                                title: res.data.msg,
                                type: "error",
                            });
                        }
                    })
                    .catch(() => {
                        Toast({
                            title: '未知错误，请联系客服',
                            type: "success"
                        })
                    });
            }

            //  登录成功处理
            const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
            function updateToken(token: string) {
                localStorage.setItem("token", token);
            }
            function handleUserInfo(data: object) {
                UPDATE_USERINFO(data);
            }
            //获取图形验证码
            const codeImg = ref("/M/Server/GetVerifyCodeImg");
            function getCodeImg() {
                codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
            }

            //注册提交方法
            function handleCodeLogin() {
                const formData = new FormData();
                // formData.append("invitationCode", '1385417619640823810');
                formData.append("addType", addType.value as any);
                if(invitationCode.value){
                    formData.append("invitationCode", invitationCode.value as any);
                }
                formData.append("userName", telphone.value);
                formData.append("smsCode", code.value);
                formData.append("passCode", passCode.value);//图形验证码
                // formData.append("rePassword", passwordNew.value);
                api.post("/M/Server/doRegisterOfGiftLuxury", formData)
                    .then((res) => {
                        if (res.data.success) {
                            updateToken(res.data.token);
                            handleUserInfo(res.data.obj);
                            ctx.emit("loginsuccess");

                            if(addType.value && addType.value =='2' && res.data.msg == '用户信息已存在，无法记入邀请次数'){
                                Modal({
                                    title: "温馨提示",
                                    content:res.data.msg,
                                    onConfirm: () => {
                                        router.push({
                                            name: "sendGiftOflLuxury",
                                        });
                                    },
                                    onClose: () => {
                                        console.log('1')
                                    },
                                });
                            }else{
                                Toast({
                                    title: "登录成功",
                                    type: "success",
                                });
                                router.push({
                                    name: "sendGiftOflLuxury",
                                });
                            }
                        } else {
                            Toast({
                                title: res.data.msg,
                                type: "error",
                            });
                        }
                    }).catch(() => {
                        Toast({
                          title: "提交异常，请联系客服",
                          type: "error",
                        });
                    });
            }

            function changeNum() {
                if(code.value.length > 6){
                    code.value = code.value.slice(0, 6)
                }
            }

            return {
                addType,
                telphone,
                code,
                codeText,
                invitationCode,
                getSMSCode,
                handleCodeLogin,
                changeNum,
                codeImg,
                getCodeImg,
                passCode,
                passwordNew,
                userName
            };
        },
    });
