export default  {
    "Index": "https://www.bzwz.com/",
    "Interest": "https://www.bzwz.com/",
    "News": "https://www.bzwz.com/nlist/nlist_0_1.html",
    "NewsDetail": "https://www.bzwz.com/html_news/news_{{newsid}}_1.html",
    "DocDetail": "https://www.bzwz.com/Library/LibraryDetail/{{docId}}.html",
    "Forget": "https://www.bzwz.com/",
    "About": "https://www.bzwz.com/about.html",
    "Topic": "https://www.bzwz.com/BBS/index.html",
    "TopicDetail": "https://www.bzwz.com/bbs/topic_{{topicId}}_1.html",
    "PostTopic": "https://www.bzwz.com/BBS/TopicPosts.html?type=1",
    "product": "https://www.bzwz.com/plist_1/plist_1_0_0_1.html",
    "specialProCategory": "https://www.bzwz.com/product/standard/list_0_0_1.html",
    "specialProduct": "https://www.bzwz.com/product/standard/list_0_0_1.html",
    "GB31658Product": "https://www.bzwz.com/product/latestGB/list.html",
    "bnccdetail": "https://www.bzwz.com/pro/p8/{{classId}}/p_{{id}}.html",
    "standard": "https://www.bzwz.com/standard/index.html",
    "customize": "https://www.bzwz.com/Product/SearchOpenSearchNo.html",
    "standarddetail": "https://www.bzwz.com/s_{{classId}}/s_{{id}}.html",
    "Order": "https://www.bzwz.com/User/NeiOrders.html",
    "Invoice": "https://www.bzwz.com/AokeNei/GetNoApplyInvoice.html",
    "Intergral": "https://www.bzwz.com/User/CreditLogs.html",
    "Exchange": "https://www.bzwz.com/User/ExchangeProduct.html",
    "ExchangeDetail": "https://www.bzwz.com/",
    "IntergralRule": "https://www.bzwz.com/User/Integralrule.html",
    "Bill": "https://www.bzwz.com/AokeNei/GetUserAccount.html",
    "UserInfo": "https://www.bzwz.com/User/UserInfo.html",
    "FootPrint": "https://www.bzwz.com/",
    "Message": "https://www.bzwz.com/",
    "Attention": "https://www.bzwz.com/{{uid}}/MyFollowUser_1.html",
    "Unsubscribe": "https://www.bzwz.com/{{uid}}/FollowMyUser_1.html",
    "Collection": "https://www.bzwz.com/User/MyCollection.html",
    "Account": "https://www.bzwz.com/User/index.html",
    "ChangeMobile": "https://www.bzwz.com/User/BindAccount",
    "ChangePwd": "https://www.bzwz.com/User/BindAccount",
    "Prize": "https://www.bzwz.com/AokeNei/GetUserPrizes.html",
    "Suggest": "https://www.bzwz.com/User/Guestbook.html",
    "ShopCar": "https://www.bzwz.com/ShoppingCart",
    "SubmitOrder": "https://www.bzwz.com/ShoppingCart/Checkout",
    "OpenSearch": "https://www.bzwz.com/",
    "Personal": "https://www.bzwz.com/{{uid}}.html",
    "signIn": "https://www.bzwz.com/User/index.html",
    "Course": "https://www.bzwz.com/Course/index.html",
    "CourseCommentDetail": "https://www.bzwz.com/",
    "TopicCommentDetail": "https://www.bzwz.com/",
    "videodetail": "https://www.bzwz.com/TrainingDetail/{{id}}_1_1.html",
    "livedetail": "https://www.bzwz.com/LiveDetail/{{id}}_1_1.html",
    "videoRealtime": "https://www.bzwz.com/livePlay/{{id}}.html",
    "videoRealtimeBncc": "https://www.bzwz.com/livePlay/{{id}}.html?fenlei={{fenlei}}",
    "Address": "https://www.bzwz.com/User/Address",
    "addAddress": "https://www.bzwz.com/User/Address",
    "Chat": "https://www.bzwz.com",
    "OrderDetail": "https://www.bzwz.com/User/OrderDetail?num={{num}}",
    "NeiOrderDetail": "https://www.bzwz.com/User/NeiOrderDetail?ONO={{id}}",
    "Login": "https://www.bzwz.com/",
    "InitTel": "https://www.bzwz.com/",
    "ApplyInvoice": "https://www.bzwz.com/AokeNei/GetNoApplyInvoice.html",
    "Lottery": "https://www.bzwz.com/",
    "StartJob": "https://www.bzwz.com/",
    "StartJobReal": "https://www.bzwz.com/",
    "Home": "https://www.bzwz.com/",
    "Mine": "https://www.bzwz.com/User/index.html",
    "Search": "https://www.bzwz.com/",
    "MidTest": "https://www.bzwz.com/Event/midAutumnFestivalTry",
    "MidDetail": "https://www.bzwz.com/Event/midAutumnFestivalPage",
    "MidAutumn": "https://www.bzwz.com/Event/midAutumnPage2022",
    "Anniversary2022":"https://www.bzwz.com/Event/anniversary",
    "workStart":"https://www.bzwz.com/Event/workStart2023",
    "workStart2024":"https://www.bzwz.com/Event/workStart2024",
    "commencementCeremony":"https://www.bzwz.com/Event/commencementCeremony",
    "midAutumn2024":"https://www.bzwz.com/Event/midAutumnPage2024",
    "dragonBoat2024":"https://www.bzwz.com/Event/dragonBoatPage2024",
    "dragonBoat2023":"https://www.bzwz.com/Event/dragonBoatFestival2023",
    "sendGiftOflLuxury":"https://www.bzwz.com/Event/sendGiftOflLuxury",
    "InviteRegister":"https://www.bzwz.com/Event/inviteRegister?invitationCode={{invitationCode}}",
    "virtuallyInfo":"https://www.bzwz.com/User/virtuallyInfoShow/{{id}}.html",
    "Error": "https://www.bzwz.com/"
}
