<template>
  <div class="login-wrap">
    <div class="login_banner">
        <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/login-top-img20200930.png">
    </div>
    <ul class="title-ul">
        <li data-type="tel" style="width: 100%; text-align: center;" class="active">你好&nbsp;请绑定手机号码</li>
    </ul>

    <div class="form-wapper">
        <div class="form tel-form">
            <div class="form-item">
                <label for="tel">
                    <div class="form-label">
                        <input placeholder="请输入手机号" v-model="telphone" id="tel" name="tel" type="text">
                    </div>
                </label>
            </div>
            <div class="form-item code-item">
                <label for="code">
                    <div class="form-label code-form-label">
                        <input placeholder="请输入验证码" v-model="code" id="code" @input="changeNum" name="code" type="text">
                    </div>
                </label>
                <button id="getCode" @click="getSMSCode" v-if="codeText == '获取验证码'">{{ codeText }}</button>
                <button id="getCode" v-else>{{ codeText }}</button>
            </div>
            <div class="form-item">
                <label for="pass">
                    <div class="form-label">
                        <input placeholder="设置密码" v-model="password" id="pass" name="pass" type="password">
                    </div>
                </label>
            </div>
            
            <div class="form-item">
                <label for="repeatPass">
                    <div class="form-label">
                        <input placeholder="请再次输入密码" id="repeatPass" v-model="repeatPass" name="repeatPass" type="password">
                    </div>
                </label>
            </div>
        </div>

        <div id="code-login" @click="handleCodeLogin" class="submit">
            立即登录
        </div>
    </div>
</div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  reactive,
  onMounted,
  nextTick,
  computed,
} from "vue";
import fixedHeader from "@/components/common/Header.vue";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "InitTel",
  emits: ["loginsuccess"],
  setup(props, ctx) {
    const router = useRouter();
    const route = useRoute();
    const isActive = ref(0);
    //code 登录
    const telphone = ref<string>("");
    const code = ref<string>("");
    const password = ref<string>("");
    const repeatPass = ref<string>("");
    const sendCodeFlag = ref<boolean>(true);
    const codeText = ref<string>("获取验证码");
    let timer: any = ref(null);
    const count = ref(60);

    function checkTel(tel: string) {
      if (tel === "") {
        Toast({
          title: "请输入手机号码",
          type: "warning",
        });
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (tel.length != 11 || !retel.test(tel)) {
        Toast({
          title: "请输入正确的手机号码",
          type: "warning",
        });
        // $('#tel').focus();
        return false;
      }
      return true;
    }

    //获取验证码
    function getSMSCode() {
      if (!checkTel(telphone.value)) {
        return false;
      }
      if( !sendCodeFlag.value ){
         return false
      }
      sendCodeFlag.value = false;
      /**
       *  获取验证码
       * */
      const formData = new FormData();
      formData.append("tel", telphone.value);
      formData.append("option", "getcode");
      api
        .post("/M/Server/GetNewSMSCode", formData)
        .then((res) => {
          if (res.data.success) {
            Toast({
              title: "验证码发送成功，请注意查收",
              type: "success",
            });
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //     title: '未知错误，请联系客服',
          //     type: "success"
          // })
        });
      count.value = 60;
      codeText.value = count.value + "s";
      timer = setInterval(function () {
         count.value--;
        if (count.value == 0) {
          clearInterval(timer);
          sendCodeFlag.value = true;
          codeText.value = "获取验证码";
        } else {
          codeText.value = count.value + "s";
        }

      }, 1000);
    }

    //  登录成功处理
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }

    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }

    //验证码登录
    function handleCodeLogin() {
      const formData = new FormData();
      formData.append("tel", telphone.value);
      formData.append("smscode", code.value);
      formData.append("password", password.value);
      formData.append("password2", repeatPass.value);
      api
        .post("/M/Server/DoWeixinTelReg", formData)
        .then((res) => {
          if (res.data.success) {
            updateToken(res.data.token);
            handleUserInfo(res.data.obj);
            Toast({
              title: "登录成功！",
              type: "success",
            });
            ctx.emit("loginsuccess");
            const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
            router.push(redirect)
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //   title: "未知错误，请联系客服",
          //   type: "success",
          // });
        });
    }

    function changeNum() {
      if(code.value.length > 6){
        code.value = code.value.slice(0, 6)
      }
    }


    return {
      isActive,
      telphone,
      code,
      password,
      repeatPass,
      codeText,
      getSMSCode,
      handleCodeLogin,
      changeNum
    };
  },
});
</script>

<style lang="scss" scoped>
.login_banner img {
  width: 10rem;
}

.title-ul {
  overflow: hidden;
}

.title-ul li {
  float: left;
  position: relative;
  width: 50%;
  height: 0.867rem;
  line-height: 0.867rem;
  text-align: center;
  color: #999999;
  font-size: 0.4rem;
}

.title-ul .active {
  color: #444;
}

.title-ul .active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0.267rem;
  height: 0.04rem;
  background-color: #d64b4b;
  margin-left: -0.133rem;
  opacity: 1;
}

.form-wapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.267rem;
  padding-top: 0.773rem;
}

.form-label {
  width: 100%;
  height: 1.2rem;
  line-height: 1.2rem;
  background-color: #f4f4f4;
  border-radius: 0.067rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
  margin-bottom: 0.587rem;
}

.form-label input {
  outline: none;
  border: none;
  width: 100%;
  height: 1.2rem;
  line-height: 1.2rem;
  vertical-align: top;
  background-color: transparent;
}
.code-item {
  position: relative;
  padding-right: 3.467rem;
  box-sizing: border-box;
}
.code-item button {
  position: absolute;
  right: 0;
  top: 0.32rem;
  width: 2.4rem;
  height: 0.8rem;
  border-radius: 0.067rem;
  border: solid 0.027rem #d64b4b;
  line-height: 0.8rem;
  text-align: center;
  color: #d64b4b;
  font-size: 0.373rem;
  background: none;
  outline: none;
  display: block;
}

.submit {
  margin-top: 0.987rem;

  display: block;
  width: 100%;
  height: 1.2rem;
  background-color: #d64b4b;
  box-shadow: 0 0.133rem 0.267rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.133rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.4rem;
  margin-top: 1rem;
}

.forget-password {
  margin-top: 0.267rem;
  color: red;
  float: right;
  margin-left: 0.533rem;
  font-size: 0.32rem;
}
.login-wrap {
  // position: fixed;
  // left: 0;
  // top: 0;
  // background: #fff;
  // height: 100%;
  // width: 100%;
  // padding-top: 55px;
  padding-bottom: 70px;
}
.login-transform {
  transform: translateX(0);
}

.weixin-login {
  width: 45%;
}
.submit-weixin {
  display: flex;
  justify-content: space-between;
}

.login-enter-active,
.login-leave-active {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login-enter-from,
.login-leave-to {
  transform: translateX(100%);
}
</style>
