<template>
  <header>
    <img
            class="back"
            @click="back"
            src="@/assets/img/leftBack.svg"
            alt=""
    />
    <div class="title">2024年度领导投票</div>
    <h1 class="logo">
      <a href="javascript:void(0);">
        {{ userInfo.avatar }}
        <img
                style="border-radius: 50%"
                src="/img/wxshare/logo-weiyegg.png"
                alt=""
                v-if="!userInfo.userImg"
        />
        <img style="border-radius: 50%" :src="userInfo.userImg" alt="" v-else />
      </a>

    </h1>
  </header>
  <main class="main-container" v-if="voteListA.length>0 || voteListB.length>0 || voteListC.length>0">
    <div class="search">
      <button class="query" @click="refreshVote">点击刷新</button>
    </div>
  </main>

  <div class="vote-list" v-if="voteListA.length>0 || voteListB.length>0  || voteListC.length>0">
    <div class="vote-title">业务（投票）</div>
    <ul class="vote-content">
      <table border="1" cellspacing="0" cellpadding="10.013px">
        <thead>
        <tr>
          <th class="detail-name">排名</th>
          <th>姓名</th>
          <th>票数</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in voteListA" :key="index">
          <td class="name">{{index+1}}</td>
          <td>{{item.staffName}}</td>
          <td>{{item.votingTotal}}</td>
        </tr>
        </tbody>
      </table>
    </ul>

    <div class="vote-title">实验室（投票）</div>
    <ul class="vote-content">
      <table border="1" cellspacing="0" cellpadding="10.013px">
        <thead>
        <tr>
          <th class="detail-name">排名</th>
          <th>姓名</th>
          <th>票数</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in voteListB" :key="index">
          <td class="name">{{index+1}}</td>
          <td>{{item.staffName}}</td>
          <td>{{item.votingTotal}}</td>
        </tr>
        </tbody>
      </table>
    </ul>
    <div class="vote-title">后勤（投票）</div>
    <ul class="vote-content">
      <table border="1" cellspacing="0" cellpadding="10.013px">
        <thead>
        <tr>
          <th class="detail-name">排名</th>
          <th>姓名</th>
          <th>票数</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in voteListC" :key="index">
          <td class="name">{{index+1}}</td>
          <td>{{item.staffName}}</td>
          <td>{{item.votingTotal}}</td>
        </tr>
        </tbody>
      </table>
    </ul>
  </div>


  <div class="empty" v-if="!(voteListA.length>0 || voteListB.length>0 || voteListC.length>0) && !loading">
    <img src="@/assets/img/common/icon36.png" alt="">
    <div>暂无投票结果</div>
  </div>

  <loading :options="loadOption" v-if="loading"></loading>

</template>

<script lang="ts">
  import { defineComponent, inject, onMounted, ref, nextTick} from "vue";
  import { useRouter, useRoute } from "vue-router";
  import $ from "jquery";
  import axios from "@/api/axios";
  import qs from "qs";
  import Toast from "@/components/UI/Toast";
  import Modal from "@/components/UI/Modal";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import { parthrottle } from '@/utils/utils'
  export default defineComponent({
    name: "leadVoteView",
    components: {
      Loading
    },
    setup() {

      const voteListA = ref<object>([])
      const voteListB = ref<object>([])
      const voteListC = ref<object>([])
      const router = useRouter();
      const route = useRoute();
      //  展示用户信息
      const userInfo = inject("userInfo") as any;

      const loading = ref(false);
      const loadOption = {
        text: "正在加载...",
        color: "#df0024",
        textColor: "#df0024",
      };

      onMounted(function() {
        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        }

        loading.value = true;
        axios
                .get("/M/Staff/votingStatisticsLeader2025")
                .then(res => {
                  loading.value = false;
                  if (res.data.success) {
                    const data = res.data.obj
                    voteListA.value = data.staffList101;
                    voteListB.value = data.staffList102;
                    voteListC.value = data.staffList103;
                    nextTick(() => {
                      $('.home-wapper').css('padding-top', '0')
                      const height = Number($(window).height()) - Number($('header').outerHeight())-Number($('main').outerHeight()) + 'px'
                      $('.vote-list').css({'height': height})
                    })
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                      onClose: () => {
                        router.push({
                          name: "leadVote"
                        });
                      },
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                });
      })

      function refreshVote() {
        window.location.reload()
      }


      function back() {
        router.back();
      }

      return {
        userInfo,
        voteListA,
        voteListB,
        voteListC,
        loading,
        loadOption,
        back,
        refreshVote
      }
    },
  });
</script>

<style lang="scss" scoped>
  header {
    width: 375px;
    height: 55px;
    box-sizing: border-box;
    position: fixed;
    padding: 0 10px;
    padding-left: 20px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 10;
    border-bottom: 1px solid #eee;
  }
  header .back {
    width: 20px;
    height: 20px;
  }
  header .title {
    width: 8rem;
    font-size: 18px;
    text-align: center;
  }
  .logo {
    width: 27px;
    height: 27px;
    margin: 0;
  }
  .logo a {
    display: block;
    width: 27px;
    height: 27px;
  }
  .logo img {
    width: 27px;
    height: 27px;
    display: block;
  }

  main {
    margin-top: 55px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    // position: fixed;
    // background: #fff;
    // width: 100%;
    // top: 55px;
  }

  main .search {
    display: flex;
    justify-content: flex-end;
  }

  main .search select {
    font-size: 14px;
    color: #333;
  }

  .challengeWhellSelect {
    width: 1.6rem;
    width: 35%;
    height: 0.5rem;
    height: 0.73333rem;
    background: none;
    font-family: 'Microsoft YaHei';
    border: 1px solid #eee;
  }

  .query {
    // margin-top: 30px;
    // margin-left: 10%;
    width: 1.93333rem;
    height: 0.73333rem;
    // height: 30px;
    // width: 60px;
    // height: 1.2rem;
    background-color: #d64b4b;
    box-shadow: 0 0.13333rem 0.26667rem 0 rgb(0 0 0 / 30%);
    border-radius: .1rem;
    line-height: 0.73333rem;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    font-size: .4rem;
    padding: 0;
  }

  .satisfaction {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 20px;
  }
  .satisfaction span b {
    color: #D64B4B;
  }

  .satisfaction .percent {
    color: #D64B4B;
    font-size: 14px;
    margin-top: 5px;
  }

  .vote-list {
    padding: 0 20px 20px 20px;
    // position: fixed;
    // bottom: 0;
    width: 100%;
    overflow-y: auto;
    word-wrap:break-word;
    word-break:break-all;
  }

  .vote-content {
    // overflow-y: auto;
    margin-bottom: 20px;
  }

  .vote-content li {
    font-size: .42667rem;

    padding: 10px 0;
    border-top: 1px solid #eee;

    display: flex;
    flex-wrap: wrap;
  }

  .vote-content .vote-title {
    color: #D64B4B;
  }

  .vote-content .title-color {
    color: #009688;
  }

  .vote-content .vote-time {
    position: relative;
    top: 2px;
    float: right;
    color: #888;
    font-size: 14px;
    img {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      // top: -2px;
    }
    span {
      position: relative;
      top: 3px;
    }
  }
  .iszan {
    color: #df0024!important;
  }

  .vote-content .vote-remark {
    margin-top: 10px;
  }
  .total {
    background-color:#F1F3F4;
    font-size: 14px;
    color: #888;
    padding: 0 6px 10px 10px;
    text-align: right;
  }

  table {
    width: 100%;
    border: none;
    margin: 0 auto;
    border-collapse: collapse;
    border-color: #ccc;
    text-align: center;
  }

  thead {
    width: 355.013px;
    background-color: #666666;
    border: 1px solid #666;
    text-align: center;
  }
  th {
    color: #fff;
    text-align: center;
    height: 30px;
    box-sizing: border-box;
    padding: 0 9px;
    line-height: 30px;
    font-size: 13.988px;
  }

  td {
    font-size: 13.988px;
    color: #666666;
    padding: 10.013px;
    word-break: break-word;
  }

  .name {
    width: 20%;
    box-sizing: border-box;
    border-right-color: #e5e5e5;
    background-color: #f2f2f2;
    line-height: 25.013px;
  }

  .vote-list .vote-title {
    font-size: 16px;
    color: #606266;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .empty {
    padding-top: 50%;
    text-align: center;
  }

  .empty img {
    width: 35%;
  }

  .empty div {
    color: #9f9f9f;
    font-size: 0.3733rem;
    margin-top: 0.266rem;
  }
</style>
