<template>
    <div class="sweepstakes_wrap">
        <div class="start_content">

            <div class="sweepstakes_title">
                <p style="font-weight: bold;">活动内容 <span>回答内容即可抽奖</span></p>
                <div class="sweepstakes_guan">
                    我们的官网是？www.<input
                    type="text"
                    name="name"
                    id="Q_answer"
                    class="sweepstakes_input"
                    maxlength="4"
                    />.com <br/> 其中bzwz是
                    <select class="challengeWhellSelect">
                        <option value="">请选择</option>
                        <option value="pzyz">被子袜子</option>
                        <option value="yzyz">杯子包子</option>
                        <option value="bzwz">标准物质</option>
                    </select>的拼音首字母
                </div>
            </div>
            <div class="sweepstakes_content">
                <canvas class="Sweepstakes_dibu" id="myCanvas" width="700" height="550">
                    当前浏览器版本过低，请使用其他浏览器尝试
                </canvas>
                <img class="Sweepstakes_start" src="@/assets/img/lottery/start.png" id="start">
            </div>
        </div>

        <div class="pc-lottery">
            <p>PC端登录www.bzwz.com，可再次抽奖。</p>
            <img src="@/assets/img/startGift/start_three2025.png" alt="" style="width: 82%;">
        </div>

        <div class="start_integral">
            <div class="start_product">
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p1_2024.png" alt="">
                    <div>坚果投影机</div>
                </div>
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p_2_2024.png" alt="">
                    <div>华为P60手机</div>
                </div>
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p3_2024.png" alt="">
                    <div>京东100元E卡</div>
                </div>
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p4_2024.png" alt="">
                    <div>小米电动牙刷</div>
                </div>
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p5_2024.png" alt="">
                    <div>美的养生壶</div>
                </div>
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p6_2024.png" alt="">
                    <div>小熊挂烫机</div>
                </div>
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p7_2024.png" alt="">
                    <div>加热鼠标垫</div>
                </div>
                <div class="start_pic">
                    <img src="@/assets/img/startGift/p8_2024.png" alt="">
                    <div>蓝牙鼠标</div>
                </div>
            </div>
            <div class="start_pro_more">
                <router-link
                    :to="{
                        name: 'Intergral',
                    }"
                >
                    <a class="sweepstakes_btn">查看更多礼品>></a>
                </router-link>
            </div>
        </div>

        <div class="start_rule">
            <div class="start_rule_content">
                <p>1.本次活动对象伟业计量新老客户。</p>
                <p>2.活动时间：2025年02月06日——2025年02月15日。</p>
                <p>3.活动期间每人共有两次抽奖机会，伟业计量PC端一次，伟业计量微信公众号一次。</p>
                <p>4.抽奖礼品兑换后，将在一周内寄出，请耐心等待。</p>
            </div>
        </div>

        <red-prize
          v-if="showRedPrize"
          @close="onClosePrize"
          :src="awardImg"
          :awardName="awardName"
          hint="恭喜您获得"
        ></red-prize>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import RedPrize from "./RedPrize.vue";
import { useRouter } from "vue-router";
import qs from "qs";
export default defineComponent({
    name: "commencementCeremony",
    components: {
      RedPrize
    },
    setup(){

      ($.fn as any).WheelSurf = function (options: any) {
          const _default = {
              outerCircle: {
                  color: '#df1e15'
              },
              innerCircle: {
                  color: '#f4ad26'
              },
              dots: ['#fbf0a9', '#fbb936'],
              disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'],
              title: {
                  color: '#5c1e08',
                  font: '19px Arial'
              }
          }

          $.extend(_default,options)
          // 画布中心移动到canvas中心

          /* eslint-disable */
          const _this = this[0],
              width = _this.width,
              height = _this.height,
              ctx = _this.getContext("2d"),
              imgs: any = [],
              awardTitle: any = [],
              awardPic: any = []
          for (const item in (_default as any).list) {
              awardTitle.push((_default as any).list[item].name)
              imgs.push((_default as any).list[item].image)
          }
          const num = imgs.length
          // 圆心
          const x = width / 2
          const y = height / 2
          ctx.translate(x, y)

          return {
              init: function (angelTo: any) {
                  angelTo = angelTo || 0;

                  ctx.clearRect(-this.width, -this.height, this.width, this.height);

                  // 平分角度
                  const angel = (2 * Math.PI / 360) * (360 / num);
                  let startAngel = 2 * Math.PI / 360 * (-90)
                  let endAngel = 2 * Math.PI / 360 * (-90) + angel

                  // 旋转画布
                  ctx.save()
                  ctx.rotate(angelTo * Math.PI / 180);
                  // 画外圆
                  ctx.beginPath();
                  ctx.lineWidth = 25;
                  ctx.strokeStyle = _default.outerCircle.color;
                  ctx.arc(0, 0, 243, 0, 2 * Math.PI)
                  ctx.stroke();
                  // 画里圆
                  ctx.beginPath();
                  ctx.lineWidth = 23;
                  ctx.strokeStyle = _default.innerCircle.color;
                  ctx.arc(0, 0, 218, 0, 2 * Math.PI)
                  ctx.stroke();

                  // 装饰点
                  const dotColor = _default.dots
                  for (let i = 0; i < 12; i++) {
                      // 装饰点 圆心 坐标计算
                      ctx.beginPath();
                      const radius = 230;
                      const xr = radius * Math.cos(startAngel)
                      const yr = radius * Math.sin(startAngel)

                      ctx.fillStyle = dotColor[i % dotColor.length]
                      ctx.arc(xr, yr, 11, 0, 2 * Math.PI)
                      ctx.fill()

                      startAngel += (2 * Math.PI / 360) * (360 / 12);

                  }
                  // 画里转盘
                  const colors = _default.disk
                  for (let i = 0; i < num; i++) {
                      ctx.beginPath();
                      ctx.lineWidth = 208;
                      ctx.strokeStyle = colors[i % colors.length]
                      ctx.arc(0, 0, 104, startAngel, endAngel)
                      ctx.stroke();
                      startAngel = endAngel
                      endAngel += angel
                  }
                  // 添加奖品
                  function loadImg() {

                      const dtd = $.Deferred()
                      let countImg = 0
                      if (awardPic.length) {
                          return dtd.resolve(awardPic);
                      }
                      for (let i = 0; i < num; i++) {
                          const img = new Image()
                          awardPic.push(img)

                          img.src = imgs[i]
                          img.onload = function () {
                              countImg++
                              if (countImg == num) {
                                  dtd.resolve(awardPic);
                              }
                          }
                      }
                      return dtd.promise()
                  }

                  $.when(loadImg()).done(function (awardPic) {

                      startAngel = angel / 2
                      for (let i = 0; i < num; i++) {
                          ctx.save();
                          ctx.rotate(startAngel)
                          ctx.drawImage(awardPic[i], -36, -23 - 130, 70, 70);
                          ctx.font = _default.title.font;
                          ctx.fillStyle = _default.title.color
                          ctx.textAlign = "center";
                          ctx.fillText(awardTitle[i], 0, -170);
                          startAngel += angel
                          ctx.restore();
                      }
                  })
                  ctx.restore();
              },
              /**
               * @param angel 旋转角度
               * @param callback 转完后的回调函数
               */
              lottery: function (angel: any, callback: any) {
                  angel = angel || 0
                  angel = 360-angel
                  angel += 720
                  // 基值（减速）
                  const baseStep = 30
                  // 起始滚动速度
                  const baseSpeed = 0.3
                  // 步长
                  let count = 1;
                  const _this = this
                  const timer = setInterval(function () {

                      _this.init(count)
                      if (count == angel) {
                          clearInterval(timer)
                          if (typeof callback == "function") {
                              callback()
                          }
                      }

                      count = count + baseStep * (((angel - count) / angel) > baseSpeed ? baseSpeed : ((angel - count) / angel))
                      if (angel - count < 0.5) {
                          count = angel
                      }

                  }, 25)
              }
          }

      }

      const showRedPrize = ref(false)
      const awardName = ref()
      const awardImg = ref()
      const router = useRouter();
      //初始化查看奖品链接
      let rewardType=3;
      var wheelSurf: any
      // 初始化装盘数据 正常情况下应该由后台返回
      var initData = {
          "success": true,
          "list": [
              {
                  "id": 1,
                  "name": "黄金足金999金条5g",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/ea3f901d2d36a6de61e7da9fcd64eb03.png",
                  "rank": 1,
                  "percent": 15
              },
              {
                  "id": 2,
                  "name": "美的破壁机",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/6e6c33d5eb6fb3c03c90e01dfada46f3.png",
                  "rank": 2,
                  "percent": 10
              },
              {
                  "id": 3,
                  "name": "高档水杯",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/bce26976ef9a3fc3a161fa086aa42475.png",
                  "rank": 3,
                  "percent": 10
              },
              {
                  "id": 4,
                  "name": "1888积分",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/cec80f3630637841cad9395ca3889d8a.png",
                  "rank": 4,
                  "percent": 15
              },
              {
                  "id": 5,
                  "name": "100元优惠券",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/fa6a14958a04963580718369ea57540e.png",
                  "rank": 5,
                  "percent": 10
              },
              {
                  "id": 6,
                  "name": "588积分",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/cec80f3630637841cad9395ca3889d8a.png",
                  "rank": 6,
                  "percent": 10
              },
              {
                  "id": 7,
                  "name": "护眼台灯",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/3bf47d994daf194f59d0418a7dee87b9.png",
                  "rank": 7,
                  "percent": 15
              },
              {
                  "id": 8,
                  "name": "食品环境课程礼盒",
                  "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/4681529b00112ff043ccb1a16b0d48c9.png",
                  "rank": 8,
                  "percent": 15
              },
          ]
      }

      // 计算分配获奖概率(前提所有奖品概率相加100%)
      function getGift() {
          var percent = Math.random() * 100
          var totalPercent = 0
          for (var i = 0, l = initData.list.length; i < l; i++) {
              totalPercent += initData.list[i].percent
              if (percent <= totalPercent) {
                  // console.log(initData.list[i]);
                  return initData.list[i];
              }
          }
      }

      var list = {}
      var angel = 360 / initData.list.length
      // 格式化成插件需要的奖品列表格式
      for (var i = 0, l = initData.list.length; i < l; i++) {
          list[initData.list[i].rank] = {
              id: initData.list[i].id,
              name: initData.list[i].name,
              image: initData.list[i].image
          }
      }
      var throttle = true;


      onMounted(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            router.push({
                name: "Login",
                query: {
                    redirect: router.currentRoute.value.fullPath,
                },
            });
            return false;
        }

        // 定义转盘奖品
        wheelSurf = ($('#myCanvas') as any).WheelSurf({
            list: list, // 奖品 列表，(必填)
            outerCircle: {
                color: '#e74b44' // 外圈颜色(可选)
            },
            innerCircle: {
                color: '#f4ad26' // 里圈颜色(可选)
            },
            //dots: ['#fbf0a9', '#fbb936'], // 装饰点颜色(可选)
            dots: ['#fff7af', '#fafffe'],
            //disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'], //中心奖盘的颜色，默认7彩(可选)
            disk: ['#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0'],
            title: {
                color: '#5c1e08',
                font: '16px Arial'
            } // 奖品标题样式(可选)
        })
        // 初始化转盘
        wheelSurf.init()

        $("#Q_answer").blur(function () {
          const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
          if (answer == "") {
            Toast({
              title: "要先输入网址才能抽奖哦",
              duration: 2000,
              type: "warning"
            });

            $(this).focus();
            return false;
          }
          if (answer != "bzwz") {
            Toast({
              title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
              type: "warning",
              duration: 2000
            });

            $(this).focus();
            return false;
          }
        });
        $(".challengeWhellSelect").blur(function () {
          const challengeWhellSelect = $(".challengeWhellSelect").val();
          console.log("challengeWhellSelect:",challengeWhellSelect)
            if (challengeWhellSelect == "") {
                Toast({
                    title: "回答完问题才可参与抽奖哦",
                    duration: 2000,
                    type: "warning"
                });

                $(this).focus();
                return false;
            }
          if (challengeWhellSelect != "bzwz") {
            //layer.msg("选择错误。bzwz是“标准物质”的拼音首字母", { time: 1500 });
            Toast({
              title: "选择错误。bzwz是“标准物质”的拼音首字母",
              type: "warning",
              duration: 2000,
            });

            return false;
          }
        });
        $("#start").on('click', function () {
            const token = localStorage.getItem("token");
            if (!token) {
              Modal({
                title: "温馨提示",
                content: "登录后才可以进行抽奖哦~",
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
              return false;
            }
            const answer = ($("#Q_answer").val() as any).trim().toLowerCase();
            const challengeWhellSelect = $(".challengeWhellSelect").val();
            if (answer == "") {
              Toast({
                title: "要先输入网址才能抽奖哦",
                type: "warning",
                duration: 2000,
              });

              $("#Q_answer").focus();
              return false;
            }
            if (answer != "bzwz") {
              Toast({
                title: "您的网址似乎输入错误，提示：答案是标准物质的首字母bzwz",
                type: "warning",
                duration: 2000,
              });
              $("#Q_answer").focus();
              return false;
            }
            if (challengeWhellSelect == "") {
              Toast({
                title: "回答完问题才可参与抽奖哦",
                type: "warning",
                duration: 2000,
              });
              $("#challengeWhellSelect").focus();
              return false;
            }
            if (challengeWhellSelect != "bzwz") {
              Toast({
                title: "选择错误。bzwz是“标准物质”的拼音首字母",
                type: "warning",
                duration: 2000,
              });
              return false;
            }
            var winData: any; // 正常情况下获奖信息应该由后台返回

            axios
                .post("/M/Event/doCommonActivityNew",
                    qs.stringify({
                        eventUniqueMark: 'START_WORK_2025',
                        tag: 1,
                        answer: answer
                    })
              )
              .then((res: any) => {
                if (res.data.success) {
                    winData = res.data.data
                    rewardType=res.data.data.rewardType
                    throttle = false;
                    var count = 0
                    // 计算奖品角度
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            if (winData.rewardNum == list[key].id) {
                                winData.image = list[key].image
                                break;
                            }
                            count++
                        }
                    }

                    // 转盘抽奖，
                    wheelSurf.lottery((count * angel + angel / 2), function () {
                        awardName.value = winData.rewardName
                        awardImg.value = winData.image
                        showRedPrize.value = true
                        throttle = true;
                    })

                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });

      })


      function onClosePrize() {
        showRedPrize.value = false;
        if(rewardType===1){
            router.push({
                name: "Intergral"
            });

        }else if(rewardType===2){
            router.push({
                name: "Coupon"
            });
        }else {
            router.push({
                name: "Prize"
            });
        }



      }
        if(localStorage.getItem('WX')){
            setTimeout(function () {
                location.reload();
            },1000)
        }
        localStorage.removeItem('WX')
      return {

        showRedPrize,
        onClosePrize,
        awardImg,
        awardName
      }
    }
});
</script>


<style lang="scss" scoped>
.sweepstakes_wrap {
    padding: 0.2rem;
    background: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/9980978cf509a7d06d8d9fa4b0c38a7a.jpg');
    background-size: cover;
    padding-bottom: 50px;
}

.sweepstakes_wrap h1 {
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 22px;
}

.start_content {
    background: url('../assets/img/startGift/start_one2025.png') no-repeat;
    background-size: 93%;
    margin-top: 11.6rem;
    margin-left: 0.7rem;
    height: 12rem;
}

.sweepstakes_title {
  margin: 0 auto;
  width: 72%;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 0;
}

.sweepstakes_title p {
    font-size: 14px;
}

.sweepstakes_title p span {
    font-size: 12px;
    font-weight: 100;
}

.sweepstakes_title textarea {
  width: 100%;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #808080;
}

.sweepstakes_guan {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.sweepstakes_guan input {
  width: 65px;
  height: 23px;
  line-height: 23px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  text-align: center;
  font-size: 14px;
  background-color: #db1d13ed;
  color: #fff;
  overflow: hidden;
}
.sweepstakes_guan input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #db1d13ed inset;
    -webkit-text-fill-color: #fff;
}

.sweepstakes_title span {
font-size: 0.25rem;
display: inline-block;
}

.sweepstakes_title span a {
    color: #0072bb;
}




.sweepstakes_content {
    text-align: center;
    position: relative;
    padding: 0.4rem 0;
}

.Sweepstakes_start {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.75rem;
    /*margin-top: -0.75rem;*/
    margin-top: -1.05rem;
    cursor: pointer;
    width: 1.5rem;
}

.Sweepstakes_dibu {
    width: 100%;
}

.challengeWhellSelect {
    width: 2.3rem;
    height: 20px;
    line-height: 20px;
    background: none;
    background-color: #db1d13ed;
    color: #fff;
    font-family: 'Microsoft YaHei';
    font-size: 12px;
    border: 1px solid;
}

.challengeWhellTitle {
    text-align: center;
    font-size: 0.28rem;
    line-height: 0.5rem;
}

.Sweepstakes_bottom_content {
    line-height: 0.6rem;
}

    .Sweepstakes_bottom_content p a {
        text-decoration: underline;
        color: #2a2aca;
    }

.Choice {
    width: 100%;
    height: auto;
    margin-top: 0.1rem;
}

label {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.Choice input {
    vertical-align: middle;
    margin-right: 10px;
}

.lottery-text {
    position: absolute;
    left: 11%;
    top: 49.5%;
    width: 80%;
    /* margin-left: -40%; */
    z-index: 20;
    color: #ec3f3f;
    font-size: 0.20rem;
    text-align: center;
}

#winnerIMG {
    width: 1.5rem;
    height: 1.3rem;
    position: absolute;
    top: -1.8rem;
    right: 1.25rem;
}

.pc-lottery {
    background: url('../assets/img/startGift/start_two2025.png') no-repeat;
    background-size: 93%;
    margin-left: 0.7rem;
    padding: 20px;
    height: 7.9rem;
}
.pc-lottery p {
    color: #fff;
    font-size: 12px;
    padding-top: 0.6rem;
    text-align: center;
}
.pc-lottery img {
    width: 82%;
    padding-top: 0.5rem;
    margin-left: 0.45rem;
}

.start_integral {
    background: url('../assets/img/startGift/start_four2025.png') no-repeat;
    background-size: 93%;
    margin-left: 0.7rem;
    margin-top: 0.8rem;
    padding: 20px;
    height: 8.3rem;
}
.start_product {
   /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;*/
    margin-top: 0.8rem;
}
.start_pic {
    width: 60px;
    height: 100px;
    float: left;
    margin-left: 0.3rem;
    img {
        width: 60px;
        height: 60px;
    }
    div {
        font-size: 10px;
        color: #fff;
        text-align: center;
        position: relative;
        top: -4px;
    }
}
.start_product :nth-child(1){
    margin-left: 0rem;
}
.start_product :nth-child(5){
    margin-left: 0rem;
}
.start_pro_more {
    text-align: center;
    padding-bottom: 10px;
}
.sweepstakes_btn {
    margin: 0 auto;
    display: inline-block;
    width: 65%;
    height: 0.9rem;
    background-color: #F8D2AE;
    box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.2);
    border-radius: 45px;
    line-height: 0.9rem;
    text-align: center;
    color: #A50D09 !important;
    font-size: 0.4rem;
    font-weight: bold;
}

.start_rule {
    background: url('../assets/img/startGift/start_five2025.png') no-repeat;
    background-size: 93%;
    margin-left: 0.7rem;
    margin-top: 0.5rem;
    height: 6.4rem;
}

.start_rule_content {
    color: #fff;
    font-size: 12px;
    padding: 1.4rem 0.5rem;

}

.start_rule_content p {
    line-height:0.7rem;
}
</style>
